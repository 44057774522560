<script>
export default {
  name: 'WSMixin',
  data () {
    return {
      // ws数据
      wsData: {
        // 是否自动重连
        autoReconnect: true,
        // 最大重连次数
        maxReconnectCount: 5,
        // 当前重连次数
        reconnectCount: 0,
        // 是否正在连接中
        connecting: true,
        // 连接对象
        connection: null
      }
    }
  },
  methods: {
    // 建立ws连接
    buildWebSocket (wsApi) {
      // 连接次数验证
      if (this.wsData.reconnectCount >= this.wsData.maxReconnectCount) {
        return
      }
      // 连接
      this.wsData.reconnectCount++
      this.wsData.connecting = true
      this.wsData.connection = new WebSocket(`ws://${window.location.host}/api${wsApi}`)
      this.wsData.connection.onopen = () => {
        this.wsData.autoReconnect = true
        this.wsData.connecting = false
        // 接收消息
        this.wsData.connection.onmessage = event => {
          const res = JSON.parse(event.data)
          if (!res.success) {
            this.$message.warning(res.message)
            return
          }
          this.__handleWsMessage(res.data)
        }
      }
      // 关闭后自动重连
      this.wsData.connection.onclose = () => {
        if (this.wsData.autoReconnect) {
          this.initWs()
        }
      }
    },
    // 处理WS消息数据
    __handleWsMessage () {},
    // 发送消息
    sendWsMessage (directive, data) {
      this.wsData.connection.send(JSON.stringify({
        directive, data
      }))
    }
  }
}
</script>

<style scoped>

</style>
