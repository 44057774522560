<template>
  <div class="practice-report">
    <div class="user">
      <img :src="data.user.avatar">
      <img v-if="data.scoreLevel === 1" src="../../../assets/images/practice/1.png" class="score-level">
      <img v-else-if="data.scoreLevel === 2" src="../../../assets/images/practice/2.png" class="score-level">
      <img v-else-if="data.scoreLevel === 3" src="../../../assets/images/practice/3.png" class="score-level">
      <img v-else-if="data.scoreLevel === 4" src="../../../assets/images/practice/4.png" class="score-level">
      <img v-else-if="data.scoreLevel === 5" src="../../../assets/images/practice/5.png" class="score-level">
    </div>
    <ul class="info">
      <li><label>正确率：</label><span>{{data.correctRatio}}%</span></li>
      <li><label>错误数：</label><em>{{data.faultCount}}</em></li>
      <li><label>作答时间：</label><span>{{data.usedTime | secondsText}}</span></li>
    </ul>
    <PipaTable :data="data.techList">
      <el-table-column
        prop="techName"
        label="技术"
      >
      </el-table-column>
      <el-table-column
        prop="ratio"
        label="技术占比"
        width="85"
        align="center"
      >
        <template slot-scope="{ row }">{{ row.ratio }}%</template>
      </el-table-column>
      <el-table-column
        prop="faultCount"
        label="答错数"
        width="85"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="faultRatio"
        label="答错占比"
        width="85"
        align="right"
      >
        <template slot-scope="{ row }">{{ row.faultRatio }}%</template>
      </el-table-column>
    </PipaTable>
    <div class="score-wrap">
      <div>
        <label slot="reference">
          综合评分
          <el-popover
            placement="top"
            title="综合评分"
            width="350"
            trigger="click"
            popper-class="score-popper"
          >
            <div class="content-wrap">
              <p><label>说明：</label><span>综合评分是专项练习的评分机制，最低分0分，最高分100分。</span></p>
              <div>
                <label>计算规则：</label>
                <div>
                  <p>CR - (UT - QC * K) / K</p>
                  <ul>
                    <li><label>CR</label><span>正确率</span></li>
                    <li><label>UT</label><span>耗时（秒）</span></li>
                    <li><label>QC</label><span>问题数量</span></li>
                    <li><label>K</label><span>答题平均速度（默认30秒）</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <Icon slot="reference" type="el-icon-question"/>
          </el-popover>
          ：
        </label>
        <em v-if="data.score != null">{{data.score}}</em>
        <em v-else class="none">-</em>
        <span>分</span>
      </div>
    </div>
  </div>
</template>

<script>
import PipaTable from '@/components/common/PipaTable'
export default {
  name: 'PracticeReport',
  components: { PipaTable },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";

.practice-report {
  text-align: center;
  // 用户
  .user {
    img {
      width: 100px;
      &:first-of-type {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        vertical-align: middle;
      }
      &.score-level {
        width: 150px;
        position: relative;
        top: -10px;
        left: 5px;
        transform: skewY(-15deg);
      }
    }
    em {
      font-size: 45px;
      vertical-align: middle;
      color: $font-color-red;
      font-style: italic;
    }
  }
  // 练习信息
  .info {
    display: flex;
    justify-content: space-evenly;
    font-size: 16px;
    padding: 0;
    margin: 20px 0;
    li {
      label {
        font-size: 14px;
        color: $font-color-third;
      }
      span {
        font-weight: bold;
      }
      em {
        color: $font-color-red;
        font-style: normal;
      }
    }
  }
  // 综合评分
  .score-wrap {
    margin-top: 20px;
    text-align: left;
    .el-popover__reference-wrapper .icon {
      color: $font-color-third;
      position: relative;
      top: -1px;
    }
    em {
      font-size: 30px;
      color: $font-color-red;
      font-weight: bold;
      letter-spacing: 2px;
      &.none {
        color: $font-color-third;
        position: relative;
        top: 2px;
        font-weight: normal;
      }
    }
    & > div > span {
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/style/variables";
.score-popper .content-wrap{
  padding: 10px 0;
  & > p, & > div {
    display: flex;
    margin: 0 0 10px 0;
    label {
      display: inline-block;
      width: 80px;
      flex-shrink: 0;
      text-align: right;
      color: $font-color-third;
    }
    span {
      flex-grow: 1;
    }
    p {
      margin: 0;
    }
    ul {
      margin: 5px 0 0 0;
      padding: 0;
      label {
        text-align: left;
        width: 30px;
        margin-right: 5px;
      }
    }
  }
}
</style>
