<template>
  <div class="practice-report-view">
    <h2>练习报告</h2>
    <p>初级WEB前端开发工程师</p>
    <div class="report-wrap">
      <div class="report-detail" v-if="report != null">
        <PracticeReport :data="report"/>
        <PracticeReport v-if="reports.length > 1" :data="anotherReport"/>
      </div>
      <div class="ads">
        <img src="../../../assets/images/ads/assess-h.png" @click="handleAds">
      </div>
      <div class="report-list" v-if="reports.length > 2">
        <h3><label>成绩单</label></h3>
        <PracticeReportList :data="reports"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getReport, getReports } from '@/api/user.practice'
import html2canvas from 'html2canvas'
import PracticeReport from '@/components/question/practice/Report'
import BaseUser from '@/components/base/BaseUser'
import PracticeReportList from '@/components/question/practice/ReportList'
import WSMixin from '@/components/mixins/WSMixin'
export default {
  name: 'report',
  extends: BaseUser,
  mixins: [WSMixin],
  components: { PracticeReportList, PracticeReport },
  data () {
    return {
      // 当前用户报告
      report: null,
      // 参赛者报告
      reports: []
    }
  },
  computed: {
    anotherReport () {
      return this.reports.find(item => item.user.id !== this.userInfo.id)
    }
  },
  methods: {
    // 截图
    toImage () {
      html2canvas(this.$el)
        .then(canvas => {
          this.$el.appendChild(canvas)
        })
    },
    // 获取练习报告
    getReport () {
      getReport(this.$route.params.practiceNo)
        .then(data => {
          this.report = data
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 获取练习报告
    getReports () {
      getReports(this.$route.params.practiceNo)
        .then(data => {
          this.reports = data
          this.buildWebSocket(`/ws/practice/${this.$route.params.practiceNo}`)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    // 广告处理
    handleAds () {
      window.open('/appoint/interview')
    },
    // 处理WS消息数据
    __handleWsMessage (data) {
      // 同步练习报告数据
      if (data.directive === 'REPORTS_DATA') {
        this.reports = data.data
        return
      }
      // 新人加入
      if (data.directive === 'JOIN_PRACTICE') {
        this.$notify.info(`${data.data.user.nickname}加入了练习！`)
        this.reports = data.data.reports
      }
    }
  },
  mounted () {
    this.getReport()
    this.getReports()
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";
.practice-report-view {
  background-color: #fff;
  padding: 50px 0;
  margin-top: 20px;
  h2 {
    text-align: center;
    font-size: 30px;
    margin: 0 0 30px 0;
  }
  & > p {
    text-align: center;
    font-size: 20px;
  }
  .report-wrap {
    padding: 0 20px;
    // 详细报告
    .report-detail {
      display: flex;
      justify-content: space-around;
      h3 {
        font-weight: normal;
      }
      .practice-report {
        width: 500px;
      }
    }
    // 广告
    .ads {
      margin-top: 20px;
      img {
        width: 100%;
        border-radius: 20px;
        opacity: .3;
        transition: all ease .3s;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    // 成绩单
    .report-list {
      h3 {
        border-bottom: 1px solid #eee;
        height: 0;
        position: relative;
        margin: 50px 0 20px 0;
        label {
          display: block;
          width: 100px;
          background-color: #fff;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -50px;
          margin-top: -12px;
          font-weight: normal;
          color: $font-color-third;
        }
      }
    }
  }
}
</style>
