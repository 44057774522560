<template>
  <div class="pipa-table">
    <el-table :data="data" :stripe="true">
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'PipaTable',
  props: {
    data: {
      type: Array
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/style/variables";
  .pipa-table {
    width: 100%;
    padding: 15px 20px;
    background: $background-color-second;
    border-radius: 15px;
    box-sizing: border-box;
    /deep/ .el-table {
      background: $background-color-second;
      &::before {
        display: none;
      }
      tr {
        background-color: transparent !important;
      }
      th {
        background-color: transparent !important;
      }
      td {
        background-color: transparent !important;
      }
      .el-table__header {
        th {
          background-color: transparent;
          font-size: 15px;
          font-weight: normal;
          border-bottom: 0;
        }
      }
      .el-table__body {
        .el-table__row {
          background-color: transparent;
          border-radius: 100px;
          overflow: hidden;
          &:hover td{
            background-color: transparent;
          }
          &:nth-of-type(odd) {
            background-color: #fff !important;
            &:hover td{
              background-color: #fff !important;
            }
            td:first-of-type {
              border-top-left-radius: 100px;
              border-bottom-left-radius: 100px;
            }
            td:last-of-type {
              border-top-right-radius: 100px;
              border-bottom-right-radius: 100px;
            }
          }
          td {
            padding: 15px 0;
            font-size: 15px;
            border-bottom: 0;
          }
        }
      }
    }
  }
</style>
