<template>
  <div class="practice-report-list">
    <PipaTable :data="data">
      <el-table-column
        type="index"
        label="排名"
        width="120"
        class-name="ranking"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="参赛者"
        class-name="user-info"
      >
        <template slot-scope="{ row }">
          <img :src="row.user.avatar">
          <span>{{row.user.nickname}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="correctRatio"
        label="正确率"
        width="120"
        align="center"
      >
        <template slot-scope="{ row }">{{row.correctRatio}}%</template>
      </el-table-column>
      <el-table-column
        prop="faultCount"
        label="错误数"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="usedTime"
        label="作答时间"
      >
        <template slot-scope="{ row }">{{row.usedTime | secondsText}}</template>
      </el-table-column>
      <el-table-column
        prop="score"
        label="综合评分"
        width="120"
        class-name="score"
        align="right"
      >
      </el-table-column>
    </PipaTable>
  </div>
</template>

<script>
import PipaTable from '@/components/common/PipaTable'
export default {
  name: 'PracticeReportList',
  components: { PipaTable },
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/variables";

.practice-report-list {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  // 排名列
  /deep/ td.ranking {
    text-align: center;
    .cell {
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
  // 排名:1
  /deep/ .pipa-table .el-table .el-table__row:nth-of-type(1){
    td.ranking .cell {
      background-color: #DF5F55;
      color: #fff;
    }
  }
  // 排名:2
  /deep/ .pipa-table .el-table .el-table__row:nth-of-type(2){
    td.ranking .cell {
      background-color: $primary-color;
      color: #fff;
    }
  }
  // 排名:3
  /deep/ .pipa-table .el-table .el-table__row:nth-of-type(3){
    td.ranking .cell {
      background-color: $font-color;
      color: #fff;
    }
  }
  // 用户列
  /deep/ td.user-info .cell{
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  // 综合评分
  /deep/ td.score {
    .cell {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
