import request from '@/utils/request'

// 开始答题
export function startPractice (data) {
  return request.post('/usr/practice/start', data)
}

// 获取练习题目
export function fetchPracticeRecordList (practiceNo) {
  return request.get(`/usr/practice/${practiceNo}/data`)
}

// 答题
export function answer (data) {
  return request.post('/usr/practice/answer', data)
}

// 获取练习报告
export function getReport (practiceNo) {
  return request.get(`/usr/practice/${practiceNo}/report`)
}
// 获取练习报告
export function getReports (practiceNo) {
  return request.get(`/usr/practice/${practiceNo}/reports`)
}

// 邀请
export function invite (practiceNo) {
  return request.get(`/usr/practice/invite/${practiceNo}`)
}

// 加入邀请
export function join (data) {
  return request.post('/usr/practice/join', data)
}
